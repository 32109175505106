<div class="row m-0 pt-5 position-relative">
  <div class="centere-red-item"></div>
  <div class="row w-100 m-0 justify-content-center header" id="szobak">Szobák</div>
  <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 pb-5 p-0 d-flex order-sm-1">
    <div class="col-6 p-0">
      <img class="img-one" src="https://firebasestorage.googleapis.com/v0/b/manzard-7246c.appspot.com/o/IMG_1586.jpg?alt=media&token=f98e86dc-8314-4c43-a8db-2279e82d4868">
    </div>
    <div class="col-6">
      <img class="img-two d-flex pt-5" src="https://firebasestorage.googleapis.com/v0/b/manzard-7246c.appspot.com/o/IMG_1590.jpg?alt=media&token=0a6ebdeb-0ed9-4242-9f86-403b509b3457">
      <img class="img-two d-flex pt-5" src="https://firebasestorage.googleapis.com/v0/b/manzard-7246c.appspot.com/o/IMG_1596.jpg?alt=media&token=33466069-316d-49d0-b4d6-37914704f41c">
    </div>
  </div>
  <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 my-auto order-sm-2">
    <div class="row m-0 pb-5 "> <span class="text-border-bottom sub-header">Manzard szoba I.</span></div>
    <ul class="list-unstyled">
      <li class="pb-3">1 éjszakától <span class="bolder-font">7 000 Ft</span> /fő/éj</li>
      <li class="pb-3">1 fő esetén a szoba ár <span class="bolder-font">12 000 Ft</span> /éj</li>
      <li>Ágyak: 1 darab francia ágy</li>
      <li class="pb-3">Pótágy: 1 db</li>
      <li> <span class="bolder-font">Felszereltség:</span> </li>
      <li>TV, WiFi, Ágynemű, Törölköző, Mini konyha</li>
    </ul>
  </div>
</div>

<div class="row m-0 pt-5 position-relative bg-dark color-light">
  <div class="centere-red-item-right"></div>
  <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 my-auto order-sm-1">
    <div class="row m-0 pb-5 "> <span class="text-border-bottom sub-header">Manzard szoba II.</span></div>
    <ul class="list-unstyled">
      <li class="pb-3">1 éjszakától <span class="bolder-font">7 000 Ft</span> /fő/éj</li>
      <li class="pb-3">1 fő esetén a szoba ár <span class="bolder-font">12 000 Ft</span> /éj</li>
      <li>Ágyak: 1 darab francia ágy</li>
      <li class="pb-3">Pótágy: 1 db</li>
      <li> <span class="bolder-font">Felszereltség:</span> </li>
      <li>TV, WiFi, Ágynemű, Törölköző, Mini konyha</li>
    </ul>
  </div>
  <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 pb-5 p-0 d-flex  order-sm-2">
    <div class="col-6">
      <img class="img-two d-flex" src="https://firebasestorage.googleapis.com/v0/b/manzard-7246c.appspot.com/o/IMG_1598.jpg?alt=media&token=82640a45-d453-4293-94f9-c8b754d8b7f7">
      <img class="img-two d-flex pt-5" src="https://firebasestorage.googleapis.com/v0/b/manzard-7246c.appspot.com/o/IMG_1599.jpg?alt=media&token=38ea9d44-9ce3-414b-9ebd-bd76b4989b4c">
    </div>
    <div class="col-6 pt-5 p-0">
      <img class="img-one" src="https://firebasestorage.googleapis.com/v0/b/manzard-7246c.appspot.com/o/IMG_1603.jpg?alt=media&token=99c4aada-b6f7-40bf-94b1-5293499ffb66">
    </div>
  </div>

</div>
