import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SightsInfoComponent } from './landing-page/sights-info/sights-info.component';


const routes: Routes = [
  {
    path: 'latnivalok',
    component: SightsInfoComponent
  },
  {
    path: '',
    component: LandingPageComponent
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
