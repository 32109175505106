<nav id="nav" class="nav navbar navbar-expand-lg navbar-dark fixed-top smooth-scroll" style="max-width: 100%">
  <a id="logo" class="navbar-brand"><img src="../../assets/images/logo-full-name-small-white.png"
      style="max-height: 70px;" /></a>
  <button id="mediaButton" class="navbar-toggler" type="button" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']" fragment="main">
          Kezdőlap
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']" fragment="szobak">
          Szobák
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']" fragment="kapcsolat">
          Kapcsolat
        </a>
      </li>
      <!--   <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']" fragment="szobak">
          Árak
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/latnivalok']" fragment="latnivalok">
          Látnivalók
        </a>
      </li>
    </ul>
  </div>
</nav>