import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sights-info',
  templateUrl: './sights-info.component.html',
  styleUrls: ['./sights-info.component.scss']
})
export class SightsInfoComponent implements OnInit {
  @Input() all:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
