<!-- Site footer -->
<footer class="site-footer pt-5">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-sm-12 col-md-3 my-auto">
        <img class="w-100 pb-5" src="../../assets/images/logo/borito01.jpg">
        <!--    <h6>Kik vagyunk, miért csináltuk, kiket várunk?</h6>
        <p class="text-justify"> Apartmanunk Edelény belvárosában található, tehát minden gyalog könnyen megközelíthető.
          Kiváló lehetőség átutazóknak akár egy éjszakára, vagy hosszabb tartózkodásra, kirándulásokhoz Borsod megyében,
          központi elhelyezkedése miatt tökéletes szállás lehet. <i href="tel:+36209607606"> Tel:+36209607606 </i>
        </p> -->
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="footer-header pb-5 ">Elérhetőségek</div>
        <div class="d-flex footer-text">
          <div class="col-4 p-0 m-0">Telefonszám:</div>
          <div class="col-8 p-0 m-0" href="tel:+36209607606" style="text-align: right;">+36209607606</div>
        </div>
        <div class="d-flex footer-text">
          <div class="col-3 p-0 m-0">E-mal:</div>
          <div class="col-9p-0 m-0" href="mail:manzardapartman@gmail.com" style="text-align: right;">
            manzardapartman@gmail.com</div>
        </div>
        <div class="d-flex myicons">
          <a href="https://www.facebook.com/Manzard-Apartman-Edel%C3%A9ny-102347101505228/" class="social-link"><i
              class="fab fa-facebook-f"></i></a>

          <a href="https://www.messenger.com/t/102347101505228" class="social-link"> <i
              class="fab fa-facebook-messenger"></i></a>
        </div>

      </div>
      <div class="col-xs-12 col-md-4 ">
        <div class="footer-header pb-5">Helyszín</div>
        <div class="d-flex footer-text">3780 Edelény</div>
        <div class="d-flex footer-text">Antal György út 16-18</div>
      </div>
    </div>
    <hr>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-6 col-xs-12">
        <p class="copyright-text">Copyright &copy; 2020 All Rights Reserved by
          <a href="#">Manzard Aparman</a>.
        </p>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12">
        <!--    <ul class="social-icons">
            <fa-icon [icon]="['fab', 'stack-overflow']"></fa-icon>
            <fa-icon icon="coffee"></fa-icon>
            <li><a class="facebook" href="#"><fa-icon [icon]="['fab', 'stack-overflow']"></fa-icon></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>   
          </ul> -->
      </div>
    </div>
  </div>
</footer>
