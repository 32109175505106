import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SendMailService {

    constructor(private _http: HttpClient) { }

    sendMessage(body) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const data = {
            "firstName": body.firstName,
            "lastName": body.lastName,
            "email": body.email,
            "phone": body.phone,
            "msg": body.msg
        }
        return this._http.post('https://us-central1-manzard-7246c.cloudfunctions.net/firestoreEmail', data, httpOptions)
    }
}