<!-- Lorem ipsum with img-->
<section class="pb-5 pt-5 container">
    <div  class="row text-center p-5  justify-content-center" id="latnivalok">
        <a class="header-2 pt-5" >Látnivalók</a>
    </div>
    <div class="row text-center p-5 pt-0 justify-content-center">
        <a class="header-3">Látnivalók Edelényben</a>
    </div>
    <div class="row">
        <div class="col-12 col-md-4 order-1">
            <img class="w-100" src="../../../assets/images/imgs/kastely.jpg" style="border-radius:20px" />
        </div>
        <div class="col-12 col-md-8 my-auto order-2 text-right">
            <p class="font-weight-bolder">Edelényi Kastélysziget ( L'Huillier-Coburg Kastély )
            </p>
            <p class="font-weight-normal"> Edelény belvárosában található a gyönyörű kastélykerttel is rendelkező,
                szépen felújított kastély. Meglátogatható egy kellemes sétára a parkban, vagy részt lehet venni egy
                tárlatvezetésen is a kastélyon belül, ami egy 3D-s kisfilmet is tartalmaz.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right text-right">
            <p class="font-weight-bolder">Borsodi tájház, és vár</p>
            <p class="font-weight-normal"> A Borsodi Tájház a város és a Bódva völgye regionális múzeuma Edelény szélén
                található és három porta lakóház és gazdasági épületegyüttesét foglalja magába. A Tájház mellett
                található Borsodi Földvár is aminek jelenleg csak az alapját lehet megtekinteni, mert a török hódítások
                alatt lerombolták.
            </p>
        </div>
        <div class="col-12 col-md-4 order-md-2 order-1 order-1">
            <img class="w-100" src="../../../assets/images/imgs/taj.jpg" style="border-radius:20px" />
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-4 order-1">
            <img class="w-100" src="../../../assets/images/imgs/csaszta.jpg" style="border-radius:20px" />
        </div>
        <div class="col-12 col-md-8 my-auto order-2 text-right">
            <p class="font-weight-bolder">Császta szőlöhegyi kilátó</p>
            <p class="font-weight-normal"> A kilátó a Császta hegy tetején épült és legfelső szintjéről páratlan kilátás
                nyílik a környékre és a Bükkre, tiszta időben pedig akár a Tátra panorámájában is gyönyörködhetünk.
            </p>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="!all">
        <a class="horizontal">
            <span class="text">
                Látnivalók a környéken
            </span>
        </a>
    </div>
    <!--     -->
    <ng-container *ngIf="all">
        <div class="row text-center p-5 pt-0 justify-content-center">
            <a class="header-3">Látnivalók a környéken</a>
        </div>

        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/aggtelek.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Aggteleki cseppkőbarlang
                </p>
                <p class="font-weight-normal"> A Világörökség részét képező felszín alatti világ legjelentősebb
                    képviselője a 25 km összhosszúságú Baradla-Domica barlangrendszer. A barlang természetes bejárata
                    Aggtelek határában messziről látható, a több mint 50 m magas sziklafal tövében nyílik.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Jósvafői Hucul ménes</p>
                <p class="font-weight-normal"> A hucul ménes, melynek központja Jósvafőn található, közel 200 lovat
                    számlál. A kancák és a csikók egy része tavasztól őszig a Jósvafőtől északra, mintegy 1,5 km-re
                    található gergés-lápai rideg ménesben van elhelyezve.
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/josfvafo.jpg" style="border-radius:20px" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/szinpetri.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Színpetri a világ legnagyobb könyve
                </p>
                <p class="font-weight-normal"> Szinpetriben, a régi vízimalom közelében álló, rekonstruált malomban
                    kapott helyet az Aggteleki Nemzeti Parkot bemutató Nagy Könyv. A múzeumban Guttenberg-korabeli,
                    rekonstruált nyomdagépek is megtekinthetőek. A Guinness World Records által elismert legnagyobb
                    könyv 2010. március 21-én készült el több éves munka eredményeképpen.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Rudabánya bányató, Rudapitekusz</p>
                <p class="font-weight-normal"> Rudabánya nevezetessége a volt külszíni vasércbánya utolsó munkahelyén
                    keletkezett bányató, ami Magyarország legmélyebb tava. Rudabánya szintén ismert mint lelőhely hiszen
                    itt tárták fel a Rudapithecus-t.
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/rudabanya.jpg" style="border-radius:20px" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/boldva.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Boldva református templom
                </p>
                <p class="font-weight-normal"> A középkori szerzetesélet Boldván a 12. századi bencés apátságból máig
                    megmaradtak hazánk egyik legjelentősebb román kori műemlékét jelentő templom és a kolostor
                    alaprajzát kirajzoló romok.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Miskolc tapolcai barlangfürdő</p>
                <p class="font-weight-normal"> A Tavas-barlangban kialakított fürdőben bárki kipróbálhatja, milyen
                    kellemes hőmérsékletű termálvízben fürödni, élvezni a tiszta, minden szennyeződéstől mentes levegőt
                    egy barlang mélyén.
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/miskolc-barlangfurdo.jpg"
                    style="border-radius:20px" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/lillafured.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Lillafüred
                </p>
                <p class="font-weight-normal"> A Miskolctól nem messze található településen számos kirándulási és
                    programlehetőség található, mint például a Palotaszálló, Függőkert, vízesés és függőkert,
                    Pisztrángtelep, stb.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Diósgyőri vár</p>
                <p class="font-weight-normal">Miskolc külvárosában található a 13. században épült vár. A korszerű
                    tárlatvezetésen kívül kipróbálható az íjászat és megtekinthető a kézműves vásár is. A szintén itt
                    található Lovagi Tornák terén is számos programlehetőség közül választhatunk
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/diosgyorivar.jpg" style="border-radius:20px" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/vadaspark.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Mikolci Vadaspark
                </p>
                <p class="font-weight-normal"> Hazánk egyik legrégebben alapított vadasparkja, aminek elődje, a királyi
                    vadaspark, az 1300-as években nyitott meg. A több mint 120 állatfaj között mindenki megtalálhatja a
                    számára legkedvesebbet, legérdekesebbet.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Szilvásvárad (Szalajka völgy )</p>
                <p class="font-weight-normal"> Szilvásvárad tökéletes célpont a kirándulni vágyók számára. Kis túra
                    során megtekinthető a Fátyolvízesés, a Szikla forrás és a Felső tó is. A kirándulás végén pedig
                    tökéletes lehetőség nyílik egy piknikezésre a Szalajka völgy ölelésében.
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/szalajka.jpg" style="border-radius:20px" />
            </div>
        </div>
        <!--     -->
        <div class="row text-center p-5 pt-0 justify-content-center">
            <a class="header-3">Szlovákiai látnivalók a közelben</a>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/dobsina.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Dobsinai jégbarlang
                </p>
                <p class="font-weight-normal"> A világörökség részét képező jégbarlang Európa legnagyobb jégbarlangjai
                    közé tartozik. A jégréteg a barlang mélyében elérheti a 30-40 métert is.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Betléri kastély</p>
                <p class="font-weight-normal"> A Betlér településen található kastély a térség egyik ékköve. Az
                    építményt körbeölelő 80 hektáron elterülő természetes park tökéletes egy kellemes sétára.
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/betler.jpg" style="border-radius:20px" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/krasznahorka.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Krasznahorka vára
                </p>
                <p class="font-weight-normal"> A hegycsúcsról kiemelkedő vár ad helyet a Betléri Múzeumnak, ahol
                    megismerhetjük a település és a vár történetét. Érdekesség, hogy a vár teljesen eredeti bútorokkal
                    van berendezve, így igazán van mit megcsodálni.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 my-auto order-md-2 order-2 text-right">
                <p class="font-weight-bolder">Szádelői völgy</p>
                <p class="font-weight-normal"> A magyar határtól nem messze található völgy a térség egyik legnépszerűbb
                    kirándulóhelye.
                </p>
            </div>
            <div class="col-12 col-md-4 order-md-2 order-1 order-1">
                <img class="w-100" src="../../../assets/images/imgs/szadeloi.jpg" style="border-radius:20px" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 order-1">
                <img class="w-100" src="../../../assets/images/imgs/kassa.jpg" style="border-radius:20px" />
            </div>
            <div class="col-12 col-md-8 my-auto order-2 text-right">
                <p class="font-weight-bolder">Kassa (Rákóczi Rodostó múzeum, Székesegyház, stb.)
                </p>
                <p class="font-weight-normal"> A város számos nevezetességgel várja a turistákat. Szinte minden
                    nevezetesség a város központjában található, így könnyen bejárhatóvá téve a települést. A
                    leghíresebb látnivalók a Szent Erzsébet dóm és a mellette található zenélő szökökút, a Székesegyház
                    és a Rákóczi Rodostó múzeum.
                </p>
            </div>
        </div>
    </ng-container>
</section>