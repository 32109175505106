import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  navigationEndSub: Subscription;
  constructor(private router: Router,) {
  }
  ngOnInit() {
    this.navigationEndSub = this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(navigationEnd => {
        console.log(navigationEnd)
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          console.log(element)
          if (element) {
            /*      element.scrollIntoView({ behavior: 'smooth', block: 'start' }); */
            setTimeout(function () { element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); }, 100);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.navigationEndSub.unsubscribe()
  }

  prepareRoute(outlet: RouterOutlet) {
    const animation = 'animation'
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[animation]
  }

  goToMainPage() {
    this.router.navigate(['/'])
  }
}
