<div class="mailing-box pt-5 pb-5" id="kapcsolat">
  <div class="header">Lépj velünk kapcsolatba</div>
  <form [formGroup]="form" class="mail-grid">
    <div class="row0 second-grid">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Vezetéknév</mat-label>
          <input matInput formControlName="firstName">
          <mat-error *ngIf="form.get('firstName').hasError('required') && submitted">
            Vezetéknév mezőt <strong>kötelező</strong> kitölteni
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Keresztnév</mat-label>
          <input matInput formControlName="lastName">
          <mat-error *ngIf="form.get('lastName').hasError('required') && submitted">
            Keresztnév mezőt <strong>kötelező</strong> kitölteni
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row1">
      <mat-form-field appearance="fill">
        <mat-label>
          E-mail cím
        </mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="form.get('email').hasError('required') && submitted">
          E-mail cím mezőt <strong>kötelező</strong> kitölteni
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('pattern') && submitted">
          E-mail cím mező <strong>helytelen</strong> formátú
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row2">
      <mat-form-field appearance="fill">
        <mat-label>
          Telefonszám (+36-00-000-0000)
        </mat-label>
        <input mask="+00-00-000-0000" matInput formControlName="phone">
        <mat-error *ngIf="form.get('phone').hasError('required') && submitted">
          Telefonszám mezőt <strong>kötelező</strong> kitölteni
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row3">
      <mat-form-field class="example-full-width">
        <mat-label>Írj nekünk bátran!</mat-label>
        <textarea style="min-height: 100px;" matInput placeholder="Írj nekünk bátran!" formControlName="msg"></textarea>
      </mat-form-field>
      <mat-error *ngIf="form.get('msg').hasError('required') && submitted">
        Üzenet mezőt <strong>kötelező</strong> kitölteni
      </mat-error>
    </div>

  </form>
  <div class="d-flex mt-5 mx-auto feedback" *ngIf="isEmailSended">
    Köszönjük az üzenetét, hamarosan visszajelzünk!
  </div>
  <div class="d-flex justify-content-center pt-5 pb-5">
    <button class="btn sendEmailBtn" (click)="sendEmail()">
      <p>
        Ajánlatkérés
      </p>
    </button>
  </div>
</div>
<!-- 
<pre>{{form.controls | json}}</pre> -->