<section>
  <div class="row m-0 w-100 bg-dark">
    <div class="row m-0 w-100 pt-5 pb-5 text-header justify-content-center">Rólunk</div>
    <div class="row m-0 responsive-width container text-small pb-5 mx-auto text-center"> Apartmanunk
      Edelény belvárosában található,
      tehát minden gyalog könnyen
      megközelíthető. Kiváló lehetőség átutazóknak akár egy éjszakára, vagy hosszabb tartózkodásra, kirándulásokhoz
      Borsod megyében, központi elhelyezkedése miatt tökéletes szállás lehet.
    </div>
  </div>

  <!-- img -->
  <div id="fancyborder" class="row m-0 justify-content-center mx-auto"
    style=" margin-top: -5em; width: 80%!important; height: 60vh; margin-top: -5em!important;">
    <div class="bg-withfancy-border">
      <img src="../../assets/images/big/IMG-5301.jpg" style="padding:15px 15px 15px 15px">
    </div>
  </div>
</section>
