import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
 /*  @ViewChild('mainListDiv') mainListDiv: ElementRef;
  @ViewChild('mediaButton') mediaButton: ElementRef;
 */
  element: HTMLElement;
  mainListDiv;
  mediaButton;
  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.mainListDiv = document.getElementById("mainListDiv");
    this.mediaButton = document.getElementById("mediaButton");
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      document.getElementById('nav').classList.add('affix');
    }
    else if (document.body.scrollTop < 20 ||     
      document.documentElement.scrollTop < 20) {
        document.getElementById('nav').classList.remove('affix');
      }
  }
  toggle() {
    this.mainListDiv.classList.toggle("show_list");
    this.mediaButton.classList.toggle("active");
  }

}
