import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SendMailService } from './sendMail.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  durationInSeconds = 5;
  isEmailSended = false;
  constructor(private fb: FormBuilder, private emailService: SendMailService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      lastName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      email: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      phone: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      msg: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(1000)]],
    });
  }
  sendEmail() {
    this.isEmailSended = false;
    this.submitted = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this._snackBar.open('Valami helytelenül lett kitöltve!', 'x', {
        duration: 5000
      });
      this.submitted = true;
      return;
    }
    this.emailService.sendMessage(this.form.value).toPromise().then(x => {
      this.isEmailSended = true;
      this.form.reset();
      this.form.markAsUntouched();
    });
  }
}
